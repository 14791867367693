@use "../utils/functions/"as*;
@use "../utils/mixins"as*;

html,
body {
  scroll-behavior: smooth;
  overscroll-behavior-y: none;
}

.page {
  height: var(--vh);
  font-family: var(--font-family-primary);
  font-weight: var(--fw-400);
  font-style: normal;
  font-size: clamp(16px, 1.8vw, 18px);
  line-height: 1.5;
  letter-spacing: 0;
  color: var(--color-grey-880);
  background-color: var(--color-white);
  font-optical-sizing: auto;

  &__body {
    min-width: 320px;
    min-height: var(--vh);
  }

  &.open {
    overflow: hidden;
  }
}

.container {
  margin: 0 auto;
  padding: 0 var(--container-offset);
  width: 100%;
  max-width: var(--container-width);

  &--large {
    max-width: var(--container-large);

    @include media(tablet) {
      max-width: var(--container-width);
    }
  }
}

.site-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto;
  min-height: var(--vh);
}

.main {
  padding-top: var(--header-height);
}

.section {
  padding: clamp(32px, 8vw, 112px) 0;
}

.overlay {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    z-index: 1;
    inset: 0;
  }

  @include media(tablet) {
    &::before {
      background-color: var(--color-grey-970);
    }
  }
}

.form {
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-self: flex-end;
  max-width: 481px;
  width: 100%;
  padding: 0;

  label {
    display: block;
  }

  input {
    display: block;
    width: 100%;
    padding: 8px;
    border-bottom: 1.2px solid var(--color-dark);
  }

  .btn {
    width: fit-content;
  }

  @include media(mobile-l) {
    align-self: center;
    max-width: 100%;

    .btn {
      width: 100%;
    }
  }
}