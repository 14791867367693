@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.hero {
  $root: &;
  &:not(.hero--approach, .hero--slider) {
    padding-top: 24px;
  }

  &__inner {
    @include cover-background;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    overflow: hidden;
    min-height: 800px;
    padding: 24px clamp(10px, 2.4vw, 24px);
    border-radius: var(--radius-main);
  }

  &__content {
    position: relative;
    z-index: 2;
    width: 100%;
    padding: clamp(16px, 2.4vw, 32px) 16px clamp(16px, 2.4vw, 32px) clamp(16px, 2.4vw, 32px);
    background-color: var(--color-white);
    border-radius: var(--radius-main);

    h1 {
      margin-bottom: 8px;
    }

    .desc {
      width: 100%;
    }
  }

  &--home {
    .hero__content {
      max-width: 794px;

      .desc {
        max-width: 379px;
        margin-bottom: clamp(16px, 2.4vw, 32px);
      }
    }
  }

  &--about {
    .hero__content {
      max-width: 814px;

      .desc {
        max-width: 540px;
      }
    }
  }

  &--approach {
    .hero__inner {
      margin-bottom: clamp(32px, 6.2vw, 88px);
    }

    .hero__content {
      max-width: 604px;

      h2 {
        margin-bottom: 8px;
      }

      .desc {
        max-width: 540px;
        width: 100%;
      }
    }
  }

  &--tours {
    .hero__content {
      max-width: 705px;

      .desc {
        max-width: 555px;
      }
    }
  }

  &--favelas {
    .hero__content {
      max-width: 1069px;

      .desc {
        max-width: 768px;
      }
    }
  }

  &--slider {
    padding-bottom: calc(clamp(32px, 8vw, 112px) - clamp(0px, 2.4vw, 24px));
    .hero__content {
      max-width: 770px;
    }

    .desc {
      max-width: 585px;
    }

    .hero__slides {
      margin-bottom: clamp(32px, 3.4vw, 48px);
    }
  }

  &--tourism {
    .hero__content {
      max-width: 832px;
    }
  }

  &--community {
    .hero__content {
      max-width: 913px;
    }

    .desc {
      max-width: 682px;
    }
  }

  @include media(tablet) {
    &__inner,
    &__content {
      border-radius: var(--radius-l);
    }

    &__inner {
      min-height: 600px;
    }

    &--tours {
      .hero__inner {
        background-position: left center;
      }
    }
  }

  @include media(mobile-m) {
    &__content {
      .btn {
        display: block;
      }
    }
  }

  @include media(mobile-s) {
    &__content {
      text-align: center;
    }
  }
}