@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.nav {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .logo {
    margin-right: clamp(16px, 2.4vw, 32px);
    max-width: 210px;
    width: 100%;
  }

  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    max-width: 785px;
    width: 100%;

    @include media(tablet) {
      position: fixed;
      left: 0;
      top: calc(var(--header-height) * 1);
      z-index: -1;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      overflow-y: auto;
      padding: 30px 16px;
      max-width: 100%;
      height: calc(var(--vh));
      background-color: var(--color-white);
      transform: translateX(150%);
      transition-property: transform;
      transition-duration: 0.4s;
    }
  }

  &__list {
    display: flex;
    align-items: center;
    margin: 0 clamp(16px, 2.4vw, 32px) 0 0;

    @include media(tablet) {
      z-index: -1;
      flex-direction: column;
      margin-right: 0;
    }
  }

  &__item {
    &:not(:last-of-type) {
      margin-right: clamp(16px, 2.4vw, 24px);

      @include media(tablet) {
        margin-right: 0;
      }
    }

    @include media(tablet) {
      margin-bottom: 30px;
    }
  }

  &__link {
    padding-bottom: 4px;
    font-size: 16px;
    line-height: 1.5;
    font-weight: var(--fw-500);
    color: var(--color-dark);
    text-transform: initial;
    white-space: nowrap;
    transition: border-bottom 0.3s ease-in-out;

    &.true {
      font-weight: var(--fw-700);
    }

    &.true,
    &:hover {
      border-bottom: 1.2px solid var(--color-dark);
    }
  }

  &__btns {
    display: flex;
    align-items: center;
    gap: 8px;

    @include media(tablet) {
      flex-direction: column;
      gap: 30px;
    }
  }
}