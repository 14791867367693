@use "../utils/functions/"as*;
@use "../utils/mixins"as*;

*[class].btn {
  display: inline-block;
  margin: 0;
  padding: 8px 24px;
  border: 1.2px solid transparent;
  font-family: var(--font-family-primary);
  font-weight: var(--fw-700);
  font-size: 16px;
  line-height: 1.5;
  color: var(--color-dark) !important;
  text-align: center;
  text-transform: capitalize;
  white-space: nowrap;
  border-radius: var(--radius-s);
  background: var(--color-white);
  transition-property: background, color, border-color;
  transition-duration: 0.4s;
  transition-timing-function: ease-in-out;
  cursor: pointer;

  &:hover,
  &--bordered {
    border: 1.2px solid var(--color-dark);
  }

  &--bordered {
    &:hover {
      color: var(--color-white) !important;
      background: var(--color-dark);
      border-color: var(--color-white);
    }
  }
}