@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.slider {
  &__inner {
    @include flex-v-center;
    gap: clamp(16px, 5.2vw, 78px)
  }

  &__name {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 554px;
    width: 100%;

    h2 {
      width: fit-content;
      margin-inline: auto;
      white-space: nowrap;
    }
  }

  &__main {
    position: relative;
    width: 100%;

    &:not(.hero__slider) {
      max-width: 811px;
    }
  }

  &__slide {
    max-width: 438px;
    width: 100%;
    height: auto;
  }

  &__arrows {
    @include flex-all-sb;
    position: absolute;
    bottom: clamp(0px, 3vw, 46px);
    right: clamp(0px, 8vw, 110px);
    z-index: 2;
    gap: 16px;
    width: fit-content;
  }

  &__arrow {
    @include cover-background;
    position: static;
    width: 28px;
    height: 24px;
    margin: 0;
    background-image: url("../images/icons/arrow-icon.svg");

    &--next {
      transform: rotate(180deg);
    }

    &.swiper-button-disabled {
      opacity: 0.2;
    }
  }

  &--request {
    .slider__name h2 {
      margin-inline: 0;
    }
  }

  &--team {
    .slider__name {
      h2 {
        padding-bottom: 32px;
      }
    }

    .slider__arrows {
      bottom: clamp(0px, 5vw, 72px)
    }
  }

  &--offers {
    .slider__inner {
      align-items: flex-start;
      justify-content: space-between;
    }

    .slider__name {
      h2 {
        margin-inline: 0;
      }
    }
  }

  &--places {
    .slider__arrows {
      position: static;
      margin-inline: auto;
    }
  }

  @include media(desktop-wide, min) {
    max-width: 1440px;
    margin-inline: auto;
  }

  @include media(tablet-wide) {
    &__main,
    &__main:not(.hero__slider) {
      max-width: 93vw;
    }

    &__name {
      width: 50%;
    }

    &__arrows {
      right: 16px;
      bottom: 0;
    }
  }

  @include media(mobile-l) {
    padding-top: clamp(40px, 8vw, 112px);

    &__inner {
      flex-direction: column;
      align-items: flex-start;
    }

    &__name {
      max-width: 100%;
      width: 100%;

      h2 {
        width: 100%;
        white-space: initial;
      }

      br {
        display: none;
      }
    }

    &__main {
      padding-top: 0;
    }

    &__slides {
      margin-bottom: 40px;
    }

    &__arrows {
      position: static;
      margin-inline: auto;
      gap: 32px;
    }
  }
}