@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.info {
  &__title {
    max-width: 802px;
    margin-inline: auto;
    margin-bottom: 8px;
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: stretch;
    gap: clamp(10px, 1.8vw, 24px);
  }

  &__item {
    padding: clamp(0px, 1.8vw, 24px);

    img {
      margin-bottom: clamp(20px, 2vw, 30px);
    }
  }

  &__light {
    opacity: 0.7;
  }

  &__name {
    margin-bottom: 8px;
    font-family: var(--font-family-primary);
    font-size: 18px;
    line-height: 1.5;
    font-weight: var(--fw-700);
    color: var(--color-dark);
  }

  &--reviews,
  &--safety {
    h2 {
      margin-bottom: clamp(32px, 3.4vw, 48px);
    }
  }

  &--reviews {
    h2 {
      text-align: center;
    }
  }

  &--safety {
    .info__item {
      @include flex-sb;

      flex-direction: column;
    }

    h3 {
      margin-bottom: 16px;
    }
  }

  &--inspiration {
    .info__texts {
      max-width: 596px;
      width: 100%;
      margin-inline: auto;
      text-align: center;
    }

    h2 {
      text-align: center;
    }
  }

  &--approach {
    .info__item {
      * {
        color: var(--color-grey-880);
      }

      h3 {
        margin-bottom: 18px;
      }
    }
  }

  @include media(tablet) {
    &__texts {
      p {
        font-size: 16px;
      }
    }
  }

  @include media(mobile-l) {
    h2 {
      text-align: left;
    }

    &__grid {
      grid-template-columns: repeat(2, 1fr);
    }

    &__item {
      &:last-of-type {
        grid-column: span 2;
      }
    }

    &--inspiration {
      .info__texts {
        max-width: 100%;
        margin-inline: 0;
        text-align: left;
      }
    }
  }

  @include media(mobile-m) {
    &__grid {
      grid-template-columns: 1fr;
    }

    &__item {
      &:last-of-type {
        grid-column: auto;
      }
    }
  }
}
