/* stylelint-disable alpha-value-notation */
/* stylelint-disable color-function-notation */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Khand:wght@600&display=swap');

:root {
  --content-width: 1216px;
  --container-offset: 12px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));

  // font-family
  --font-family-primary: "Inter", sans-serif;
  --font-family-secondary: "Khand", sans-serif;

  // font-weight
  --fw-400: 400;
  --fw-500: 500;
  --fw-600: 600;
  --fw-700: 700;

  // color
  --color-white: #ffffff;
  --color-black: #000000;
  --color-dark: #393833;

  --color-grey-970: rgba(0, 0, 0, 0.7);
  --color-grey-880: rgba(57, 56, 51, 0.8);
  --color-grey-820: rgba(57, 56, 51, 0.2);

  //  radius
  --radius-main: 40px;
  --radius-l: 24px;
  --radius-m: 16px;
  --radius-s: 8px;
  --radius-xs: 4px;
  --radius-full: 50%;
}
