@import "https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Khand:wght@600&display=swap";
:root {
  --content-width: 1216px;
  --container-offset: 12px;
  --container-width: calc(var(--content-width)  + (var(--container-offset) * 2));
  --font-family-primary: "Inter", sans-serif;
  --font-family-secondary: "Khand", sans-serif;
  --fw-400: 400;
  --fw-500: 500;
  --fw-600: 600;
  --fw-700: 700;
  --color-white: #fff;
  --color-black: #000;
  --color-dark: #393833;
  --color-grey-970: #000000b3;
  --color-grey-880: #393833cc;
  --color-grey-820: #39383333;
  --radius-main: 40px;
  --radius-l: 24px;
  --radius-m: 16px;
  --radius-s: 8px;
  --radius-xs: 4px;
  --radius-full: 50%;
}

.checkbox__field:checked + .checkbox__content:after {
  opacity: 1;
}

.checkbox__field:focus + .checkbox__content:before {
  outline: 1px solid var(--color-gray-100);
  outline-offset: 1px;
}

.checkbox__field:disabled + .checkbox__content {
  opacity: .4;
  pointer-events: none;
}

html, body {
  scroll-behavior: smooth;
  overscroll-behavior-y: none;
}

.page {
  height: var(--vh);
  font-family: var(--font-family-primary);
  font-weight: var(--fw-400);
  letter-spacing: 0;
  color: var(--color-grey-880);
  background-color: var(--color-white);
  font-optical-sizing: auto;
  font-size: clamp(16px, 1.8vw, 18px);
  font-style: normal;
  line-height: 1.5;
}

.page__body {
  min-width: 320px;
  min-height: var(--vh);
}

.page.open {
  overflow: hidden;
}

.container {
  padding: 0 var(--container-offset);
  width: 100%;
  max-width: var(--container-width);
  margin: 0 auto;
}

.container--large {
  max-width: var(--container-large);
}

@media only screen and (max-width: 992px) {
  .container--large {
    max-width: var(--container-width);
  }
}

.site-container {
  min-height: var(--vh);
  grid-template-rows: 1fr auto;
  grid-template-columns: 1fr;
  display: grid;
}

.main {
  padding-top: var(--header-height);
}

.section {
  padding: clamp(32px, 8vw, 112px) 0;
}

.overlay {
  position: relative;
}

.overlay:before {
  content: "";
  z-index: 1;
  position: absolute;
  inset: 0;
}

@media only screen and (max-width: 992px) {
  .overlay:before {
    background-color: var(--color-grey-970);
  }
}

.form {
  max-width: 481px;
  width: 100%;
  flex-direction: column;
  align-self: flex-end;
  gap: 32px;
  padding: 0;
  display: flex;
}

.form label {
  display: block;
}

.form input {
  width: 100%;
  border-bottom: 1.2px solid var(--color-dark);
  padding: 8px;
  display: block;
}

.form .btn {
  width: -moz-fit-content;
  width: fit-content;
}

@media only screen and (max-width: 768px) {
  .form {
    max-width: 100%;
    align-self: center;
  }

  .form .btn {
    width: 100%;
  }
}

.hidden {
  display: none !important;
}

.sr-only {
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  width: 1px !important;
  height: 1px !important;
  white-space: nowrap !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  border: 0 !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.dis-scroll {
  width: 100%;
  height: 100dvh;
  height: calc(var(--vh, 1vh) * 100);
  overscroll-behavior: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

h1, h2, h3, .price {
  font-family: var(--font-family-secondary);
  font-weight: var(--fw-600);
  color: var(--color-dark);
  line-height: .98;
  overflow: hidden;
}

h1, h2 {
  font-size: clamp(42px, 6.8vi, 96px);
}

h3 {
  font-size: clamp(24px, 3.4vi, 48px);
}

p {
  line-height: 1.5;
}

.price {
  font-size: clamp(20px, 2.3vi, 32px);
}

.name {
  font-weight: var(--fw-700);
  color: var(--color-dark);
}

input, textarea {
  color: var(--color-dark);
  font-size: 16px;
  line-height: 1.5;
}

input::placeholder, textarea::placeholder {
  color: var(--color-grey-820);
}

.copyright {
  font-weight: var(--fw-500);
  color: var(--color-dark);
  font-size: 16px;
  line-height: 1.21;
  display: block;
}

.copyright br {
  display: none;
}

@media only screen and (max-width: 576px) {
  .copyright {
    text-align: center;
    line-height: 1.6;
  }

  .copyright br {
    display: block;
  }
}

.header {
  z-index: 10;
  width: 100%;
  background-color: var(--color-white);
  padding: clamp(12px, 2.4vw, 24px) 0;
  transition-property: transform, padding;
  transition-duration: .15s;
  position: fixed;
  top: 0;
}

.header.hide {
  transform: translateY(-100%);
}

.nav {
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}

.nav .logo {
  max-width: 210px;
  width: 100%;
  margin-right: clamp(16px, 2.4vw, 32px);
}

.nav__inner {
  max-width: 785px;
  width: 100%;
  justify-content: space-between;
  align-items: baseline;
  display: flex;
}

@media only screen and (max-width: 992px) {
  .nav__inner {
    left: 0;
    top: calc(var(--header-height) * 1);
    z-index: -1;
    max-width: 100%;
    height: calc(var(--vh));
    background-color: var(--color-white);
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 30px 16px;
    transition-property: transform;
    transition-duration: .4s;
    position: fixed;
    overflow-y: auto;
    transform: translateX(150%);
  }
}

.nav__list {
  align-items: center;
  margin: 0 clamp(16px, 2.4vw, 32px) 0 0;
  display: flex;
}

@media only screen and (max-width: 992px) {
  .nav__list {
    z-index: -1;
    flex-direction: column;
    margin-right: 0;
  }
}

.nav__item:not(:last-of-type) {
  margin-right: clamp(16px, 2.4vw, 24px);
}

@media only screen and (max-width: 992px) {
  .nav__item:not(:last-of-type) {
    margin-right: 0;
  }

  .nav__item {
    margin-bottom: 30px;
  }
}

.nav__link {
  font-size: 16px;
  line-height: 1.5;
  font-weight: var(--fw-500);
  color: var(--color-dark);
  text-transform: initial;
  white-space: nowrap;
  padding-bottom: 4px;
  transition: border-bottom .3s ease-in-out;
}

.nav__link.true {
  font-weight: var(--fw-700);
}

.nav__link.true, .nav__link:hover {
  border-bottom: 1.2px solid var(--color-dark);
}

.nav__btns {
  align-items: center;
  gap: 8px;
  display: flex;
}

@media only screen and (max-width: 992px) {
  .nav__btns {
    flex-direction: column;
    gap: 30px;
  }
}

[class].btn {
  font-family: var(--font-family-primary);
  font-weight: var(--fw-700);
  text-align: center;
  text-transform: capitalize;
  white-space: nowrap;
  border-radius: var(--radius-s);
  background: var(--color-white);
  cursor: pointer;
  border: 1.2px solid #0000;
  margin: 0;
  padding: 8px 24px;
  font-size: 16px;
  line-height: 1.5;
  transition-property: background, color, border-color;
  transition-duration: .4s;
  transition-timing-function: ease-in-out;
  display: inline-block;
  color: var(--color-dark) !important;
}

[class].btn:hover, [class].btn--bordered {
  border: 1.2px solid var(--color-dark);
}

[class].btn--bordered:hover {
  background: var(--color-dark);
  border-color: var(--color-white);
  color: var(--color-white) !important;
}

.burger {
  --line-height: 2px;
  --buger-size: 20px;
  --move: calc(var(--buger-size) / 3);
  --move-decrement: calc(var(--move) * -1);
  --transition-time: .5s;
  --lines-color: var(--color-dark);
  width: calc(var(--buger-size)  + 5px);
  height: var(--buger-size);
  transition: opacity var(--transition-time) ease;
  background-color: #0000;
  display: none;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.burger span {
  z-index: -1;
  width: 100%;
  height: var(--line-height);
  background-color: var(--lines-color);
  transition: transform var(--transition-time) ease, background-color var(--transition-time) ease .3s;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.burger span:before, .burger span:after {
  content: "";
  width: 100%;
  height: var(--line-height);
  background-color: var(--lines-color);
  animation-duration: .4s;
  animation-timing-function: cubic-bezier(.645, .045, .355, 1);
  animation-fill-mode: forwards;
  position: absolute;
  left: 0;
}

.burger span:before {
  transform: translateY(var(--move-decrement)) rotate(0);
}

.burger span:after {
  transform: translateY(var(--move)) rotate(0);
}

.burger:hover {
  opacity: 1;
}

@media only screen and (max-width: 992px) {
  .burger {
    display: block;
  }
}

.closed.opened span {
  background-color: var(--lines-color);
  transition: background-color .2s;
}

.closed.opened span:before {
  animation-name: toplineburger;
}

.closed.opened span:after {
  animation-name: bottomlineburger;
}

.opened .nav__inner {
  transform: translateX(0);
}

.opened span {
  background-color: #0000;
  transition: background-color .2s;
}

.opened span:before, .opened span:after {
  animation-duration: .2s;
  animation-timing-function: cubic-bezier(.645, .045, .355, 1);
  animation-fill-mode: forwards;
}

.opened span:before {
  animation-name: toplinecross;
}

.opened span:after {
  animation-name: bottomlinecross;
}

.hero:not(.hero--approach, .hero--slider) {
  padding-top: 24px;
}

.hero__inner {
  min-height: 800px;
  border-radius: var(--radius-main);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  flex-direction: column;
  justify-content: flex-end;
  padding: 24px clamp(10px, 2.4vw, 24px);
  display: flex;
  overflow: hidden;
}

.hero__content {
  z-index: 2;
  width: 100%;
  background-color: var(--color-white);
  border-radius: var(--radius-main);
  padding: clamp(16px, 2.4vw, 32px) 16px clamp(16px, 2.4vw, 32px) clamp(16px, 2.4vw, 32px);
  position: relative;
}

.hero__content h1 {
  margin-bottom: 8px;
}

.hero__content .desc {
  width: 100%;
}

.hero--home .hero__content {
  max-width: 794px;
}

.hero--home .hero__content .desc {
  max-width: 379px;
  margin-bottom: clamp(16px, 2.4vw, 32px);
}

.hero--about .hero__content {
  max-width: 814px;
}

.hero--about .hero__content .desc {
  max-width: 540px;
}

.hero--approach .hero__inner {
  margin-bottom: clamp(32px, 6.2vw, 88px);
}

.hero--approach .hero__content {
  max-width: 604px;
}

.hero--approach .hero__content h2 {
  margin-bottom: 8px;
}

.hero--approach .hero__content .desc {
  max-width: 540px;
  width: 100%;
}

.hero--tours .hero__content {
  max-width: 705px;
}

.hero--tours .hero__content .desc {
  max-width: 555px;
}

.hero--favelas .hero__content {
  max-width: 1069px;
}

.hero--favelas .hero__content .desc {
  max-width: 768px;
}

.hero--slider {
  padding-bottom: calc(clamp(32px, 8vw, 112px) + -1 * clamp(0px, 2.4vw, 24px));
}

.hero--slider .hero__content {
  max-width: 770px;
}

.hero--slider .desc {
  max-width: 585px;
}

.hero--slider .hero__slides {
  margin-bottom: clamp(32px, 3.4vw, 48px);
}

.hero--tourism .hero__content {
  max-width: 832px;
}

.hero--community .hero__content {
  max-width: 913px;
}

.hero--community .desc {
  max-width: 682px;
}

@media only screen and (max-width: 992px) {
  .hero__inner, .hero__content {
    border-radius: var(--radius-l);
  }

  .hero__inner {
    min-height: 600px;
  }

  .hero--tours .hero__inner {
    background-position: 0;
  }
}

@media only screen and (max-width: 576px) {
  .hero__content .btn {
    display: block;
  }
}

@media only screen and (max-width: 475px) {
  .hero__content {
    text-align: center;
  }
}

.box {
  overflow: hidden;
}

.box__inner {
  flex-direction: column;
  justify-content: center;
  display: flex;
  position: relative;
}

.box__inner:before, .box__inner:after {
  content: "";
  z-index: -1;
  width: 100%;
  border-radius: var(--radius-main);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  bottom: 0;
}

.box__inner:before {
  left: -28px;
}

.box__inner:after {
  right: -28px;
}

.box__content {
  width: 100%;
  background-color: var(--color-white);
  border-radius: var(--radius-main);
  counter-reset: num;
}

.box__content h2, .box__content h3 {
  margin-bottom: 8px;
}

.box__content--btn .box__texts {
  margin-bottom: 32px;
}

.box__texts p:not(:last-of-type, .box__subtitle) {
  margin-bottom: 16px;
}

.box__texts:not(:last-of-type) {
  margin-bottom: clamp(32px, 3.4vw, 48px);
}

.box__texts--light {
  opacity: .8;
}

.box__name {
  position: relative;
}

.box__name:before {
  content: "0" counter(num);
  counter-increment: num;
}

.box__subtitle {
  font-weight: var(--fw-700);
  color: var(--color-grey-880);
  margin-bottom: 8px;
  font-size: clamp(18px, 1.4vi, 20px);
}

.box--asides .box__inner {
  min-height: 563px;
}

.box--asides .box__inner:before, .box--asides .box__inner:after {
  max-width: 482px;
}

.box--asides .box__content {
  max-width: 492px;
  text-align: center;
  margin-inline: auto;
  padding: clamp(10px, 2.4vw, 30px);
}

.box--left .box__inner:before {
  max-width: 816px;
  left: -28px;
}

.box--left .box__content {
  max-width: 524px;
  margin-inline: auto 0;
  padding-block: clamp(10px, 2.4vw, 32px);
  padding-inline: clamp(10px, 2.4vw, 32px) 0;
}

.box--about .box__inner:before {
  background-image: url("../images/home/about/city.jpeg");
}

.box--about .box__inner:after {
  background-image: url("../images/home/about/girl.jpeg");
}

.box--insights .box__inner:before {
  background-image: url("../images/favelas/insights/buildings.jpeg");
}

.box--insights .box__inner:after {
  background-image: url("../images/favelas/insights/streetart.jpeg");
}

.box--ethical .box__inner:before {
  background-image: url("../images/tourism/ethical/city.jpeg");
}

.box--ethical .box__inner:after {
  background-image: url("../images/tourism/ethical/building.jpeg");
}

.box--ethical .box__content {
  padding-inline: clamp(10px, 2.4vw, 20px);
}

.box--values .box__inner:before {
  background-image: url("../images/about/values/team.jpeg");
}

.box--values .box__inner:after {
  background-image: url("../images/about/values/coworkers.jpeg");
}

.box--discover .box__inner:before {
  background-image: url("../images/home/discover.jpeg");
}

.box--discover .box__content {
  border-radius: 0;
  padding-block: 0;
}

.box--story .box__inner {
  min-height: 741px;
}

.box--story .box__inner:before {
  background-image: url("../images/about/story.jpeg");
}

.box--community .box__inner:before {
  background-image: url("../images/about/community.jpeg");
}

.box--community .box__content {
  border-radius: 0;
  padding-block: 0;
}

.box--categories h2 {
  margin-bottom: clamp(32px, 3.4vw, 44px);
}

.box--categories .box__inner:before {
  background-image: url("../images/tours/categories.jpeg");
}

.box--categories .box__content {
  border-radius: 0;
  padding-block: 0;
}

.box--bogota .box__content, .box--favela .box__content {
  padding-block: 0;
}

.box--bogota .box__inner:before {
  background-image: url("../images/favelas/about.jpeg");
}

.box--favela .box__inner:before {
  background-image: url("../images/favelas/about-bottom.jpeg");
}

.box--conservation .box__inner:before {
  background-image: url("../images/tourism/conservation.jpeg");
}

.box--conservation .box__content {
  padding-inline: 10px 0;
}

.box--offers .box__content, .box--stories .box__content {
  max-width: 492px;
}

.box--offers h3 {
  width: 75%;
  margin-bottom: 16px;
}

.box--commitment .box__inner {
  min-height: 615px;
}

.box--commitment .box__inner:before {
  background-image: url("../images/tourism/commitment.jpeg");
}

@media only screen and (min-width: 1442px) {
  .box__inner:before {
    left: 0;
  }

  .box__inner:after {
    right: 0;
  }
}

@media only screen and (max-width: 1280px) {
  .box--bogota .box__content, .box--favela .box__content, .box--conservation .box__content {
    padding: clamp(10px, 2.4vw, 32px);
  }
}

@media only screen and (max-width: 992px) {
  .box__inner:before, .box__inner:after {
    border-radius: var(--radius-m);
  }

  .box__content {
    border-radius: var(--radius-m);
    padding: clamp(10px, 2.4vw, 32px);
  }

  .box--asides .box__inner {
    min-height: 450px;
  }

  .box--asides .box__inner:before, .box--asides .box__inner:after {
    max-width: 250px;
  }

  .box--bogota .box__inner:before, .box--favela .box__inner:before, .box--conservation .box__inner:before {
    background-position: -130px;
  }
}

@media only screen and (max-width: 768px) {
  .box__content {
    padding-inline: 0;
  }

  .box__content .btn {
    width: 100%;
  }

  .box--asides .box__inner {
    min-height: 500px;
    padding-top: clamp(100px, 40vw, 200px);
    padding-bottom: clamp(100px, 40vw, 200px);
  }

  .box--asides .box__inner:before, .box--asides .box__inner:after {
    top: unset;
    bottom: unset;
    height: clamp(200px, 60vw, 300px);
    max-width: 100%;
    left: 0;
    right: 0;
  }

  .box--asides .box__inner:before {
    top: 0;
  }

  .box--asides .box__inner:after {
    bottom: 0;
  }

  .box--left .box__inner {
    padding-top: calc(clamp(200px, 50vw, 380px) + 32px);
  }

  .box--left .box__inner:before {
    left: 0;
    right: 0;
    bottom: unset;
    height: clamp(200px, 50vw, 380px);
    max-width: 100%;
  }

  .box--left .box__content {
    max-width: 100%;
    padding-inline: 0;
  }

  .box--story .box__inner, .box--commitment .box__inner {
    min-height: auto;
  }

  .box--story .box__inner:before {
    background-position: 50% 15%;
  }

  .box--offers .box__content, .box--stories .box__content {
    max-width: 100%;
  }

  .box--offers h3, .box--stories h3 {
    width: 100%;
  }

  .box--bogota .box__inner:before, .box--favela .box__inner:before, .box--conservation .box__inner:before {
    background-position: center;
  }
}

@media only screen and (max-width: 576px) {
  .box--asides .box__inner:after {
    background-position: top;
  }
}

.card {
  counter-reset: num;
}

.card__title {
  margin-bottom: clamp(32px, 3.4vw, 48px);
  position: relative;
}

.card__title:before {
  content: "0" counter(num);
  counter-increment: num;
  opacity: .8;
}

.card__items {
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 1fr;
  gap: clamp(32px, 3.4vw, 48px);
  display: grid;
}

.card__items:not(:last-of-type) {
  margin-bottom: clamp(48px, 15.4vw, 224px);
}

.card__item, .card__image {
  position: relative;
}

.card__item--btn .card__texts {
  margin-bottom: 32px;
}

.card__article {
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  display: flex;
}

.card__article .btn {
  width: -moz-fit-content;
  width: fit-content;
}

.card__image {
  border-radius: var(--radius-m);
  margin-bottom: 32px;
  overflow: hidden;
}

.card__image img {
  width: 100%;
  height: revert-layer;
}

.card__price, .card__duration {
  z-index: 2;
  background-color: var(--color-white);
  border-radius: var(--radius-s);
  padding: 8px 16px;
  position: absolute;
  top: clamp(16px, 2vw, 24px);
}

.card__price {
  left: clamp(16px, 2vw, 24px);
}

.card__duration {
  left: calc(2.5 * clamp(16px, 2vw, 24px) + 46px);
}

.card__content h3:not(.card__name), .card__position {
  margin-bottom: 8px;
}

.card__name {
  margin-bottom: 32px;
}

.card--popup .card__content {
  max-width: 438px;
}

.card--popup .card__extra {
  display: none;
}

@media only screen and (max-width: 768px) {
  .card__items {
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
  }

  .card__article .btn {
    width: 100%;
  }

  .card--popup .card__content {
    max-width: 100%;
  }
}

.slider__inner {
  align-items: center;
  gap: clamp(16px, 5.2vw, 78px);
  display: flex;
}

.slider__name {
  max-width: 554px;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.slider__name h2 {
  width: -moz-fit-content;
  width: fit-content;
  white-space: nowrap;
  margin-inline: auto;
}

.slider__main {
  width: 100%;
  position: relative;
}

.slider__main:not(.hero__slider) {
  max-width: 811px;
}

.slider__slide {
  max-width: 438px;
  width: 100%;
  height: auto;
}

.slider__arrows {
  z-index: 2;
  width: -moz-fit-content;
  width: fit-content;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  display: flex;
  position: absolute;
  bottom: clamp(0px, 3vw, 46px);
  right: clamp(0px, 8vw, 110px);
}

.slider__arrow {
  width: 28px;
  height: 24px;
  background-image: url("../images/icons/arrow-icon.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0;
  position: static;
}

.slider__arrow--next {
  transform: rotate(180deg);
}

.slider__arrow.swiper-button-disabled {
  opacity: .2;
}

.slider--request .slider__name h2 {
  margin-inline: 0;
}

.slider--team .slider__name h2 {
  padding-bottom: 32px;
}

.slider--team .slider__arrows {
  bottom: clamp(0px, 5vw, 72px);
}

.slider--offers .slider__inner {
  justify-content: space-between;
  align-items: flex-start;
}

.slider--offers .slider__name h2 {
  margin-inline: 0;
}

.slider--places .slider__arrows {
  margin-inline: auto;
  position: static;
}

@media only screen and (min-width: 1442px) {
  .slider {
    max-width: 1440px;
    margin-inline: auto;
  }
}

@media only screen and (max-width: 1180px) {
  .slider__main, .slider__main:not(.hero__slider) {
    max-width: 93vw;
  }

  .slider__name {
    width: 50%;
  }

  .slider__arrows {
    bottom: 0;
    right: 16px;
  }
}

@media only screen and (max-width: 768px) {
  .slider {
    padding-top: clamp(40px, 8vw, 112px);
  }

  .slider__inner {
    flex-direction: column;
    align-items: flex-start;
  }

  .slider__name {
    max-width: 100%;
    width: 100%;
  }

  .slider__name h2 {
    width: 100%;
    white-space: initial;
  }

  .slider__name br {
    display: none;
  }

  .slider__main {
    padding-top: 0;
  }

  .slider__slides {
    margin-bottom: 40px;
  }

  .slider__arrows {
    gap: 32px;
    margin-inline: auto;
    position: static;
  }
}

.info__title {
  max-width: 802px;
  margin-inline: auto;
  margin-bottom: 8px;
}

.info__grid {
  grid-template-columns: repeat(3, 1fr);
  align-items: stretch;
  gap: clamp(10px, 1.8vw, 24px);
  display: grid;
}

.info__item {
  padding: clamp(0px, 1.8vw, 24px);
}

.info__item img {
  margin-bottom: clamp(20px, 2vw, 30px);
}

.info__light {
  opacity: .7;
}

.info__name {
  font-family: var(--font-family-primary);
  font-size: 18px;
  line-height: 1.5;
  font-weight: var(--fw-700);
  color: var(--color-dark);
  margin-bottom: 8px;
}

.info--reviews h2, .info--safety h2 {
  margin-bottom: clamp(32px, 3.4vw, 48px);
}

.info--reviews h2 {
  text-align: center;
}

.info--safety .info__item {
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

.info--safety h3 {
  margin-bottom: 16px;
}

.info--inspiration .info__texts {
  max-width: 596px;
  width: 100%;
  text-align: center;
  margin-inline: auto;
}

.info--inspiration h2 {
  text-align: center;
}

.info--approach .info__item * {
  color: var(--color-grey-880);
}

.info--approach .info__item h3 {
  margin-bottom: 18px;
}

@media only screen and (max-width: 992px) {
  .info__texts p {
    font-size: 16px;
  }
}

@media only screen and (max-width: 768px) {
  .info h2 {
    text-align: left;
  }

  .info__grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .info__item:last-of-type {
    grid-column: span 2;
  }

  .info--inspiration .info__texts {
    max-width: 100%;
    text-align: left;
    margin-inline: 0;
  }
}

@media only screen and (max-width: 576px) {
  .info__grid {
    grid-template-columns: 1fr;
  }

  .info__item:last-of-type {
    grid-column: auto;
  }
}

@keyframes toplinecross {
  0% {
    transform: translateY(var(--move-decrement));
  }

  50% {
    transform: translateY(0);
  }

  100% {
    transform: rotate(45deg);
  }
}

@keyframes bottomlinecross {
  0% {
    transform: translateY(var(--move));
  }

  50% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(0)rotate(-45deg);
  }
}

@keyframes toplineburger {
  0% {
    transform: rotate(45deg);
  }

  50% {
    transform: rotate(0);
  }

  100% {
    transform: translateY(var(--move-decrement)) rotate(0);
  }
}

@keyframes bottomlineburger {
  0% {
    transform: translateY(0)rotate(-45deg);
  }

  50% {
    transform: rotate(0);
  }

  100% {
    transform: translateY(var(--move)) rotate(0);
  }
}

.product-popup {
  background-color: var(--color-grey-970);
  z-index: 15;
  position: fixed;
  inset: 0;
}

.product-popup__box {
  max-width: 70vw;
  width: 100%;
  height: calc(var(--vh)  - 5vh);
  background-color: var(--color-white);
  border-radius: var(--radius-main);
  padding-block: clamp(12px, 6.2vw, 88px);
  padding-inline: clamp(12px, 6.2vw, 88px) calc(clamp(12px, 6.2vw, 88px) + -1 * clamp(16px, 3vw, 32px));
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.product-popup__close {
  width: 27px;
  height: 27px;
  background-color: var(--color-blue-500);
  cursor: pointer;
  border-radius: var(--radius-full);
  position: absolute;
  top: 32px;
  right: 32px;
}

.product-popup__close:before {
  content: "";
  width: 21px;
  height: 21px;
  background-image: url("../images/icons/close-icon.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.product-popup .products__offer {
  top: 24px;
  right: 0;
}

.product-popup__content {
  height: 100%;
  padding-right: clamp(10px, 3vw, 32px);
  overflow-y: scroll;
}

.product-popup__name {
  margin-bottom: 8px;
  font-size: clamp(38px, 6.8vi, 64px);
}

.product-popup__extra {
  align-items: center;
  gap: clamp(16px, 3vw, 32px);
  margin-bottom: clamp(16px, 3vw, 32px);
  display: flex;
}

.product-popup__extra span {
  font-family: var(--font-family-secondary);
  font-size: clamp(24px, 3.4vi, 48px);
  line-height: .98;
  font-weight: var(--fw-600);
  color: var(--color-grey-880);
}

.product-popup h3 {
  font-family: var(--font-family-primary);
  font-size: 20px;
  line-height: 1.5;
  font-weight: var(--fw-700);
  color: var(--color-grey-880);
  margin-bottom: 8px;
}

.product-popup__details {
  margin-bottom: clamp(16px, 3vw, 32px);
  padding-left: clamp(16px, 3vw, 32px);
}

.product-popup__details li {
  list-style: disc;
  display: list-item;
}

.product-popup__desc {
  line-height: 1.3;
  font-weight: var(--fw-400);
  margin-bottom: clamp(16px, 3vw, 32px);
}

.product-popup__desc p:not(:last-of-type) {
  margin-bottom: clamp(8px, 1.2vw, 16px);
}

.product-popup .products__price {
  font-size: clamp(30px, 3vw, 48px);
}

.product-popup .products__price--old {
  font-size: clamp(26px, 3vw, 32px);
  text-decoration: line-through;
}

.product-popup__image {
  max-width: 834px;
  width: 100%;
  margin-inline: auto;
  border-radius: var(--radius-main);
  margin-bottom: clamp(32px, 3.4vw, 48px);
  overflow: hidden;
}

.product-popup__image img {
  width: 100%;
}

.product-popup .btn {
  width: -moz-fit-content;
  width: fit-content;
  margin-inline: auto;
  display: block;
}

@media only screen and (min-width: 1442px) {
  .product-popup__box {
    max-width: 1010px;
  }
}

@media only screen and (max-width: 992px) {
  .product-popup__box {
    max-width: 90vw;
  }
}

@media only screen and (max-width: 768px) {
  .product-popup__box {
    border-radius: var(--radius-m);
    padding-block: 50px clamp(16px, 3vw, 32px);
    padding-inline: clamp(16px, 3vw, 32px) calc(clamp(16px, 3vw, 32px) - 10px);
  }

  .product-popup__close {
    top: 16px;
    right: 10px;
  }

  .product-popup__image {
    border-radius: var(--radius-m);
  }

  .product-popup .btn {
    width: 100%;
  }
}

.policy h2 {
  opacity: .8;
  margin-bottom: 8px;
  font-size: clamp(24px, 3.4vi, 48px);
}

.policy .policy__content:not(:last-child), .policy .policy__content > :not(:last-child, h2) {
  margin-bottom: clamp(16px, 3vw, 32px);
}

.policy ul {
  padding-left: clamp(16px, 3vw, 32px);
}

.policy li {
  padding-left: 32px;
  position: relative;
}

.policy li:before {
  content: "✔";
  position: absolute;
  left: 0;
}

.policy li:not(:last-of-type) {
  margin-bottom: 16px;
}

.policy a {
  color: var(--color-dark);
  font-weight: var(--fw-500);
  text-underline-offset: 6px;
  text-decoration: underline;
}

.policy strong {
  font-size: clamp(18px, 3vw, 22px);
  font-weight: var(--fw-500);
}

.footer {
  padding-bottom: clamp(0px, 1.8vw, 24px);
}

.footer__wrapper, .footer__items {
  gap: 24px;
}

.footer__wrapper {
  justify-content: space-between;
  display: flex;
}

.footer__items {
  display: flex;
}

@media only screen and (max-width: 768px) {
  .footer__wrapper {
    flex-direction: column-reverse;
    align-items: center;
  }
}

/*# sourceMappingURL=main.css.map */
